<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveVehicle)" id="user-form">
                    <h5>Datos generales</h5>
                    <b-row class="mb-2">
                        <b-col cols="12" md="4" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Marca" invalid-feedback="Ingresa la marca">
                                    <b-form-input v-model="vehicle.brand" autocomplete="false" placeholder="Marca"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Modelo" invalid-feedback="Ingresa el modelo">
                                    <b-form-input v-model="vehicle.model" autocomplete="false" placeholder="Modelo"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4" class="text-left">
                            <ValidationProvider rules="integer" v-slot="{ errors }">
                                <b-form-group label="Año" invalid-feedback="Ingresa sólo números">
                                    <b-form-input v-model="vehicle.year" autocomplete="false" placeholder="Año"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Placa" invalid-feedback="Ingresa la placa">
                                    <b-form-input v-model="vehicle.licensePlate" autocomplete="false" placeholder="Placa"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4" class="text-left">
                            <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                <b-form-group label="Capacidad de pasajeros" :invalid-feedback="passengerCapacityErrorMessage">
                                    <b-form-input v-model="vehicle.passengerCapacity" autocomplete="false" placeholder="Capacidad de pasajeros"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'VehicleIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: 'VehicleShow', params: { id: vehicle.id }}" v-if="vehicle.id" class="mr-2">
                        <font-awesome-icon icon="search"></font-awesome-icon> Detalle
                    </b-button>
                    <b-button type="submit" form="user-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { required, integer, min_value } from 'vee-validate/dist/rules';
import { vehicleUrl } from '@routes';
import * as constants from '@constants';
import Utilities from '../../js/utilities';

extend('required', {
    ...required,
    message: 'Campo requerido'
});

extend('integer', {
    ...integer,
    message: 'Campo requerido'
});

extend('min_value', {
    ...min_value,
    message: 'Campo requerido'
});

export default {
    data() {
        return {
            vehicle: {
                id: 0,
                brand: '',
                model: '',
                year: '',
                licensePlate: '',
                passengerCapacity: '',
                type: 'OWN',
                businessEntity: null,
                paymentType: null,
                amount1: 0,
                amount2: 0
            },
            swalTitle: 'Vehículos',
            breadcrumb: {
                title: 'Vehículos',
                path: [
                    {
                        name: 'VehicleIndex',
                        text: 'Vehículos'
                    }
                ]
            },
            action: 'Nuevo',
            signature: null,
            oldSignature: null,
            isLoading: false,
            file: null,
            vehicleTypes: [
                { text: 'Propio', value: 'OWN' },
                { text: 'Alquilado', value: 'RENT' }
            ]
        };
    },
    created() {
        if (this.$route.name.includes('Edit')) {
            this.loadData();

            this.action = 'Modificar';
        }

        this.breadcrumb.path.push({
            name: this.$route.name,
            text: `${this.action}`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(vehicleUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    let vehicle = response.data.vehicle;

                    this.vehicle.id = vehicle.id;
                    this.vehicle.brand = vehicle.brand;
                    this.vehicle.model = vehicle.model;
                    this.vehicle.year = vehicle.year.toString();
                    this.vehicle.type = vehicle.type;
                    this.vehicle.licensePlate = vehicle.licensePlate;
                    this.vehicle.passengerCapacity = vehicle.passengerCapacity.toString();
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        saveVehicle() {
            this.isLoading = true;

            this.axios.post(vehicleUrl(), this.vehicle).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'success'
                    });

                    this.$router.push({ name: 'VehicleShow', params: { id: response.data.id }});
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                console.error(error);

                this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    computed: {
        passengerCapacityErrorMessage() {
            let message = '';

            if (this.vehicle.passengerCapacity.trim() === '') {
                message = 'Ingresa la capacidad de pasajeros';
            } else if (!Utilities.isNumeric(this.vehicle.passengerCapacity)) {
                message = 'Ingresa sólo números';
            } else if (!Utilities.isPositiveNumber(this.vehicle.passengerCapacity)) {
                message = 'Ingresa un valor mayor que 0';
            } else if (!Number.isInteger(this.vehicle.passengerCapacity)) {
                message = 'Ingresa sólo enteros';
            }

            return message;
        }
    },
    watch: {
    }
};
</script>

<style scoped>
</style>