var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-card',{staticClass:"white-bg",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-secondary","to":{ name: 'VehicleIndex' }}},[_c('font-awesome-icon',{attrs:{"icon":"angle-left"}}),_vm._v(" Regresar")],1),(_vm.vehicle.id)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"secondary","to":{ name: 'VehicleShow', params: { id: _vm.vehicle.id }}}},[_c('font-awesome-icon',{attrs:{"icon":"search"}}),_vm._v(" Detalle ")],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","form":"user-form","variant":"primary"}},[_c('font-awesome-icon',{attrs:{"icon":"save"}}),_vm._v(" Guardar")],1)],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{attrs:{"id":"user-form"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveVehicle)}}},[_c('h5',[_vm._v("Datos generales")]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Marca","invalid-feedback":"Ingresa la marca"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Marca","state":errors[0] ? false : null},model:{value:(_vm.vehicle.brand),callback:function ($$v) {_vm.$set(_vm.vehicle, "brand", $$v)},expression:"vehicle.brand"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Modelo","invalid-feedback":"Ingresa el modelo"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Modelo","state":errors[0] ? false : null},model:{value:(_vm.vehicle.model),callback:function ($$v) {_vm.$set(_vm.vehicle, "model", $$v)},expression:"vehicle.model"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Año","invalid-feedback":"Ingresa sólo números"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Año","state":errors[0] ? false : null},model:{value:(_vm.vehicle.year),callback:function ($$v) {_vm.$set(_vm.vehicle, "year", $$v)},expression:"vehicle.year"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Placa","invalid-feedback":"Ingresa la placa"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Placa","state":errors[0] ? false : null},model:{value:(_vm.vehicle.licensePlate),callback:function ($$v) {_vm.$set(_vm.vehicle, "licensePlate", $$v)},expression:"vehicle.licensePlate"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required|integer|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Capacidad de pasajeros","invalid-feedback":_vm.passengerCapacityErrorMessage}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Capacidad de pasajeros","state":errors[0] ? false : null},model:{value:(_vm.vehicle.passengerCapacity),callback:function ($$v) {_vm.$set(_vm.vehicle, "passengerCapacity", $$v)},expression:"vehicle.passengerCapacity"}})],1)]}}],null,true)})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }